import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Section } from "../styledComponents/section"
import CardList from "../components/CardList"

const ThankYou = ({ data, location, createCookie }) => {
  const blogPosts = data.allWpPost.nodes;
  return (
    <Layout location={location}>
      <Seo
        title="The Internet Consultancy Group"
        description=""
        image=""
        url=""
      />

      <Section background="var(--orange-mid)" type="pullout-with-image">
        <h2>Thank you for getting in contact, we will be in touch within the next 48 hours.</h2>
        <svg xmlns="http://www.w3.org/2000/svg" width="298" height="218">
          <g fill="none" fillRule="evenodd">
            <ellipse cx="149" cy="109" fill="#FFF" rx="149" ry="109" />
            <g transform="translate(43 42)">
              <g fillRule="nonzero" opacity=".2" transform="translate(153 9)">
                <path fill="#3F3D56" d="M24.49 105.26c-1.467.874-2.962 1.72-4.487 2.54-.236.127-.473.253-.71.384-1.146.608-2.31 1.2-3.491 1.774a8.307 8.307 0 0 1 .923-3.006 6.87 6.87 0 0 1 .756-1.152c1.155-1.427 2.757-2.223 4.394-1.991 1.02.155 1.95.67 2.615 1.451Z" />
                <path fill="#3F3D56" d="M27.179 103.077c-.03.206-.066.407-.111.607a85.144 85.144 0 0 1-2.578 1.576c-1.467.874-2.962 1.72-4.487 2.54-.236.127-.473.253-.71.384a4.707 4.707 0 0 1-.776-.708 5.94 5.94 0 0 1-1.03-1.669 8.246 8.246 0 0 1 1.164-8.05c1.164-1.425 2.757-2.222 4.394-1.99 1.637.232 2.948 1.444 3.65 3.133a7.95 7.95 0 0 1 .484 4.177Z" />
                <ellipse cx="23.27" cy="94.311" fill="#3F3D56" rx="6.655" ry="5.136" transform="rotate(-81.82 23.27 94.311)" />
                <path fill="#6C63FF" d="M57.666 27.769a24.11 24.11 0 0 1-3.685 9.76l-14.432-2.162 13.043 4.072a24.797 24.797 0 0 1-2.295 2.495c5.678 9.731 3.89 22.043-4.326 29.798a24.032 24.032 0 0 1 3.28 13.147c-6.135 7.066-13.62 13.405-22.177 18.813-.851.533-1.712 1.056-2.584 1.568-1.467.874-2.962 1.72-4.487 2.54a23.466 23.466 0 0 1-1.486-.332 23.629 23.629 0 0 1-1.786-.524c-8.137-2.748-14.234-9.5-16.082-17.809-1.848-8.309.82-16.97 7.038-22.845a23.603 23.603 0 0 1-.704-1.292c-.066-.127-.128-.253-.194-.384a24.016 24.016 0 0 1-2.366-11.883l.029-.424c.017-.229.039-.46.062-.688.035-.322.076-.647.122-.971v-.03a23.996 23.996 0 0 1 2.402-7.516l17.596 8.769L8.589 40.49a24.446 24.446 0 0 1 3.414-4 24.133 24.133 0 0 1 .67-25.27C17.641 3.58 26.496-.686 35.638.156c9.142.842 17.049 6.652 20.508 15.068l-19.76 10.46 20.825-7.192c.753 3.034.908 6.184.454 9.276Z" />
                <path fill="#000" d="M49.246 84.87c-6.136 7.067-13.622 13.406-22.178 18.814a85.144 85.144 0 0 1-2.578 1.576c-1.467.874-2.962 1.72-4.487 2.54a23.466 23.466 0 0 1-1.486-.332 23.629 23.629 0 0 1-1.786-.524c-8.137-2.748-14.234-9.5-16.082-17.809-1.848-8.309.82-16.97 7.038-22.845a23.603 23.603 0 0 1-.704-1.292c-.066-.127-.128-.253-.194-.384a24.016 24.016 0 0 1-2.366-11.883l.029-.424c.017-.229.039-.46.062-.688.035-.322.076-.647.122-.971v-.03c.151-.519 2.153-.083 5.203.96l.644.226c.675.24 1.394.505 2.15.794a150.63 150.63 0 0 1 5.4 2.195l.125.053.268.116C31.365 60.513 48.384 69.42 45.944 71.72a24.033 24.033 0 0 1 3.302 13.15Z" opacity=".1" />
              </g>
              <g fillRule="nonzero" opacity=".2" transform="translate(0 7)">
                <path fill="#3F3D56" d="M60.994 119.889c-.804-.284-1.601-.575-2.393-.874a120.894 120.894 0 0 1-5.445-2.195c.362-.3.773-.533 1.214-.687 1.594-.558 3.344-.026 4.769 1.258a8.24 8.24 0 0 1 1.855 2.498Z" />
                <path fill="#3F3D56" d="M59.139 117.391a6.61 6.61 0 0 1-.538 1.624 120.894 120.894 0 0 1-5.445-2.195 115.605 115.605 0 0 1-4.59-2.09 9.057 9.057 0 0 1-.088-1.407 8.04 8.04 0 0 1 .153-1.46c.382-1.918 1.465-3.445 3.06-4.003 1.595-.559 3.343-.026 4.768 1.26.345.312.661.655.945 1.026a9.153 9.153 0 0 1 1.43 2.748 8.96 8.96 0 0 1 .305 4.497Z" />
                <ellipse cx="51.193" cy="106.356" fill="#3F3D56" rx="5.235" ry="7.119" transform="rotate(-17.54 51.193 106.356)" />
                <ellipse cx="48.129" cy="97.719" fill="#3F3D56" rx="5.215" ry="7.092" transform="rotate(-17.526 48.129 97.719)" />
                <path fill="#6C63FF" d="M57.394 110.146a24.508 24.508 0 0 1-8.922 3.175c-.773.113-1.543.187-2.312.222-9.242-4.683-17.538-10.411-24.619-16.954-.101-.277-.191-.556-.289-.839a27.104 27.104 0 0 1 .088-17.092c-4.734-3.244-8.27-8.009-10.044-13.538a27.178 27.178 0 0 1-1.34-8.39h20.031l-19.835-3.235a26.99 26.99 0 0 1 1.232-5.464C6.649 44.79 3.113 40.025 1.339 34.496-3.11 20.816 3.884 6.008 16.96 1.415c8.753-3.072 18.012-.78 24.513 5.17L28.44 26.051 43.958 9.208a26.496 26.496 0 0 1 4.728 8.667 27.16 27.16 0 0 1 1.201 11.122l-14.082 4.82 13.713-2.328a26.805 26.805 0 0 1-.92 3.48c4.735 3.243 8.27 8.008 10.045 13.536a27.182 27.182 0 0 1 1.339 8.164v.939a27.017 27.017 0 0 1-1.417 8 25.42 25.42 0 0 1 5.273 4.823 26.46 26.46 0 0 1 4.764 8.712c3.915 12.04-1.04 24.957-11.208 31.003Z" />
                <path fill="#000" d="M57.394 110.146a24.508 24.508 0 0 1-8.922 3.175c-.773.113-1.543.187-2.312.222-9.242-4.683-17.538-10.411-24.619-16.954-.101-.277-.191-.556-.289-.839a27.104 27.104 0 0 1 .088-17.092c-4.734-3.244-8.27-8.009-10.044-13.538-.687-2.111 27.946-3.815 40.988-2.181l.304.039c3.959.52 6.39 1.365 5.967 2.616a25.42 25.42 0 0 1 5.273 4.824 26.46 26.46 0 0 1 4.764 8.712c3.925 12.052-1.03 24.97-11.198 31.016Z" opacity=".1" />
              </g>
              <path fill="#6C63FF" fillRule="nonzero" d="M73.147 44.386a.829.829 0 0 0 .169-.483c0-1.052-2.296-1.903-5.128-1.903-2.831 0-5.123.858-5.123 1.903.004.2.077.392.21.549-3.151.328-5.275.944-5.275 1.647C58 47.149 62.706 48 68.5 48S79 47.15 79 46.099c-.002-.754-2.386-1.402-5.853-1.713Zm80.658-40.802c.167-.214.26-.467.266-.728 0-1.577-3.607-2.856-8.056-2.856-4.45 0-8.056 1.279-8.056 2.856.008.295.124.58.332.812C133.337 4.16 130 5.085 130 6.143 130 7.721 137.376 9 146.5 9c9.124 0 16.5-1.279 16.5-2.856 0-1.123-3.748-2.094-9.195-2.56Zm-64 0c.167-.214.26-.467.266-.728C90.07 1.279 86.464 0 82.015 0c-4.45 0-8.056 1.279-8.056 2.856.008.295.124.58.332.812C69.337 4.16 66 5.085 66 6.143 66 7.721 73.386 9 82.5 9S99 7.721 99 6.144c0-1.123-3.748-2.094-9.195-2.56Z" opacity=".1" />
              <path stroke="#6C63FF" strokeWidth="3" d="M70 20.929c.397-.255.929-.012 1.2.397.271.41.35.943.417 1.439.151 1.114.26 2.394-.415 3.235 1.104-3.727 4.282-6.583 7.798-7m40-17.892c.264-.146.62 0 .802.226.161.249.256.531.276.823.1.636.176 1.367-.276 1.843.788-2.17 2.805-3.722 5.198-4m27 37.108c.264-.146.62 0 .802.226.161.249.256.531.276.823.1.636.176 1.367-.276 1.843.788-2.17 2.805-3.722 5.198-4" opacity=".1" />
              <path fill="#2F2E41" fillRule="nonzero" d="M105.168 11.514c-.815.709-1.39 1.617-2.286 2.257-1.077.772-2.492 1.065-3.808 1.479-1.316.413-2.667 1.063-3.196 2.19-.45.963-.19 2.082-.571 3.068-.41 1.047-1.473 1.781-2.286 2.627-1.607 1.667-2.321 3.96-1.904 6.097.287 1.479 1.074 2.845 1.54 4.288.572 1.766.652 3.617.732 5.448.148 3.44.295 6.911-.45 10.295-.19.85-.704 13.763-1.7 13.737 2.643-.236 5.302-.261 7.958-.29L139 62.316c-1.834-2.494-3.005-17.235-3.394-20.175-.67-5.068.953-10.142 1.143-15.244.027-.657.027-1.332-.234-1.95-.275-.647-.823-1.2-1.006-1.87-.263-.96.265-1.945.282-2.93.034-1.98-1.904-3.499-3.441-4.966-1.482-1.411-2.714-3.015-4.243-4.39-1.53-1.377-3.451-2.543-5.637-2.754-1.796-.173-3.58.312-5.3.791-2.052.57-4 1.291-6.177 1.256-2.401-.044-3.88.164-5.825 1.43Z" />
              <path fill="#000" fillRule="nonzero" d="M132.648 20.656c.055.212.135.416.238.608a3.651 3.651 0 0 0-.304-1.262c-.009.22.014.44.066.654Zm-38.373 31.14-.22.02c.074.001.147-.005.22-.02Z" opacity=".1" />
              <path fill="#FBBEBE" fillRule="nonzero" d="M135.708 121.303a1.631 1.631 0 0 1-.836.358c-4.862 1.049-9.767 1.944-14.723 2.228-4.373.252-8.759.026-13.133-.2-4.375-.225-8.746-.453-13.016-1.383a36.238 36.238 0 0 0 3.29-11.955 37.1 37.1 0 0 0 .13-5.496c-.014-.22-.04-.44-.076-.658-.203-1.315-.705-3.22-.249-4.172.586-1.223 2.835-1.263 3.934-1.49a92.85 92.85 0 0 1 5.627-.978c7.478-1.07 15.031-1.313 22.565-1.551 1.012-.032 2.217.04 2.744.95.294.509.28 1.145.264 1.742a54.203 54.203 0 0 0 3.492 20.606c.251.654.482 1.51-.013 1.999Zm-29.221-69.552c-.812 1.02-1.956 1.706-3.107 2.308a38.424 38.424 0 0 1-4.243 1.918c-.294.454-.06 1.056.194 1.54 2.278 4.513 5.326 9 9.925 10.939 2.36.99 4.948 1.238 7.492 1.47.792.071 1.6.143 2.373-.051.772-.195 1.49-.664 2.136-1.162 4.213-3.252 6.987-8.08 7.713-13.42.062-.473.062-1.053-.336-1.307-.243-.152-.552-.123-.837-.12-1.862.02-3.513-1.388-4.373-3.07-.86-1.682-1.09-3.622-1.301-5.51 0-.042-10.949-.277-11.937-.285-1.542-.014-2.05.17-2.2 1.783-.158 1.78-.352 3.517-1.5 4.967Z" />
              <path fill="#000" fillRule="nonzero" d="M106.487 51.751c-.812 1.02-1.956 1.706-3.107 2.308a38.424 38.424 0 0 1-4.243 1.918c-.294.454-.06 1.056.194 1.54 2.278 4.513 5.326 9 9.925 10.939 2.36.99 4.948 1.238 7.492 1.47.792.071 1.6.143 2.373-.051.772-.195 1.49-.664 2.136-1.162 4.213-3.252 6.987-8.08 7.713-13.42.062-.473.062-1.053-.336-1.307-.243-.152-.552-.123-.837-.12-1.862.02-3.513-1.388-4.373-3.07-.86-1.682-1.09-3.622-1.301-5.51 0-.042-10.949-.277-11.937-.285-1.542-.014-2.05.17-2.2 1.783-.158 1.78-.352 3.517-1.5 4.967Z" opacity=".1" />
              <circle cx="115" cy="36" r="15" fill="#FBBEBE" fillRule="nonzero" />
              <path fill="#FF6363" fillRule="nonzero" d="M125.62 53.567c.163 2.05-22.014 3.97-22.851 1.441-.497.583-1.408.63-2.134.38s-1.338-.733-2.025-1.088c-1.147-.594-2.439-.814-3.71-1.029-2.068-.347-4.472-.602-6.004.824-.53.492-.89 1.14-1.422 1.633-.928.858-2.241 1.126-3.439 1.519-1.73.57-3.323 1.481-4.956 2.278a44.112 44.112 0 0 1-12.074 3.878c-.892.15-1.79.271-2.675.482-1.242.296-2.484.803-3.33 1.754.99 1.257 2.411 1.943 3.938 2.438l14.731 4.785c2.216.72 4.467 1.455 6.407 2.744 3.23 2.141 5.332 5.662 6.482 9.347 1.15 3.686 1.447 7.574 1.733 11.423.125 1.673.237 3.418-.424 4.967 3.432.402 6.902.345 10.318-.17 3.288-.497 6.54-1.42 9.866-1.389 3.63.036 7.148 1.206 10.727 1.838 3.579.632 7.528.651 10.543-1.367-.642-.86-.74-2.089-.764-3.156l-.077-5.056a7.458 7.458 0 0 1 .123-1.823c.256-1.082.976-1.99 1.436-3.002.889-1.953.765-4.186.814-6.33.05-2.144.352-4.447 1.81-6.015.955-1.024 2.293-1.605 3.65-1.963 2.319-.616 4.745-.656 7.141-.824A64.266 64.266 0 0 0 172 66.25c-.424-.89-1.41-1.396-2.31-1.81-2.128-.978-4.383-1.98-6.718-1.762-1.13.107-2.247.498-3.372.355-1.066-.136-2.01-.736-3.046-1.023-.785-.217-1.607-.249-2.413-.37-1.861-.283-3.607-1.045-5.329-1.798l-4.348-1.9c-1.147-.502-2.341-1.04-3.128-2.012-.256-.317-.463-.674-.742-.972-.48-.51-1.146-.813-1.813-1.023a5.908 5.908 0 0 0-2.612-.285c-.604.12-1.2.274-1.786.465-2.874.77-5.84-.03-8.762-.549Z" />
              <path fill="#2F2E41" fillRule="nonzero" d="M142 129.994A135.839 135.839 0 0 1 117.6 134H96.924A135.767 135.767 0 0 1 86 132.756a43.33 43.33 0 0 1 4.342-9.635c.292-.424.52-.895.677-1.396.086-.465.13-.94.13-1.414a7.147 7.147 0 0 1 1.495-3.951c.406.239.817.469 1.231.69 4.696 2.515 9.81 3.995 15.044 4.353 2.752.152 5.511.092 8.256-.18 6.19-.537 12.627-1.777 17.612-5.713.21-.164.417-.335.622-.51 2.423 2.32 3.824 5.874 4.827 9.196.597 1.944 1.148 3.884 1.764 5.798Z" />
              <path fill="#000" fillRule="nonzero" d="M113.85 119a2.64 2.64 0 0 0 .267 2c.234-.615.12-1.336-.285-1.811l.017-.189Z" opacity=".1" />
              <path fill="#FBBEBE" fillRule="nonzero" d="M104.604 54.789c.791-.412 1.645-.777 2.547-.789 1.353-.012 2.564.777 3.567 1.648a17.734 17.734 0 0 1 2.504 2.646c.366.476.717.999.772 1.587.055.589-.283 1.251-.887 1.391a1.627 1.627 0 0 1-1.098-.211c-1.395-.731-2.428-2.404-4.01-2.24-1.085.11-1.819 1.072-2.412 1.942-.528.777-1.062 1.576-1.299 2.474-.236.897-.122 1.942.534 2.619.51.53 1.254.777 1.95 1.053a13.67 13.67 0 0 1 4.152 2.568c.585.532 1.142 1.166 1.223 1.943.082.777-.536 1.632-1.343 1.578a2.205 2.205 0 0 1-.997-.414c-1.46-.92-2.95-1.883-4.662-2.221-1.743-.344-3.544 0-5.3.256a71.54 71.54 0 0 1-5.811.587 5.09 5.09 0 0 1-1.727-.107c-1.924-.567-2.485-2.9-2.26-4.587.238-1.803 1.63-2.213 3.086-3.108 4.094-2.553 7.131-6.361 11.471-8.615Z" />
              <path fill="#FBBEBE" fillRule="nonzero" d="M119.397 54.789c-.793-.412-1.646-.777-2.55-.789-1.353-.012-2.565.777-3.568 1.648a17.736 17.736 0 0 0-2.502 2.646c-.365.476-.716.999-.771 1.587-.055.589.281 1.251.885 1.391.382.062.774-.014 1.101-.211 1.395-.731 2.43-2.404 4.01-2.24 1.087.11 1.82 1.072 2.412 1.942.528.777 1.065 1.576 1.3 2.474.234.897.122 1.942-.535 2.619-.508.53-1.254.777-1.95 1.053a13.702 13.702 0 0 0-4.156 2.568c-.585.532-1.14 1.166-1.224 1.943-.083.777.537 1.632 1.344 1.578.365-.054.708-.197.998-.414 1.46-.92 2.95-1.883 4.664-2.221 1.743-.344 3.544 0 5.302.256a63.79 63.79 0 0 0 5.806.599 5.107 5.107 0 0 0 1.73-.107c1.923-.567 2.484-2.9 2.261-4.587-.24-1.803-1.631-2.213-3.09-3.109-4.086-2.564-7.123-6.372-11.467-8.626Z" />
              <path fill="#2F2E41" fillRule="nonzero" d="M104.188 29.943c.891-1.256 1.95-2.618 3.395-2.764 2.018-.204 3.595 2.047 5.604 2.338 2.585.376 5.126-2.575 7.556-1.497 1.193.532 2.084 2.008 3.381 1.98.897-.018 1.627-.768 2.221-1.505A27.653 27.653 0 0 0 129 24.572c-2.468-2.524-6.057-3.1-9.408-3.56l-5.634-.772c-1.356-.186-2.736-.37-4.084-.113-1.22.232-2.357.817-3.46 1.433-1.317.738-4.413 1.923-5.22 3.296-.563.952.21 1.396.936 2.355.683.91 1.369 1.821 2.058 2.732Z" />
              <path fill="#000" fillRule="nonzero" d="M96.596 64a30.98 30.98 0 0 1-7.09.46c-.932-.045-1.868-.133-2.796-.054-1.12.097-2.197.435-3.288.68-5.655 1.268-11.564.03-17.337-.666-1.02-.123-2.061-.228-3.071-.048-.925.164-1.779.559-2.597.982a31.746 31.746 0 0 0-3.796 2.316c-1.259.896-2.529 2.038-2.616 3.489-.076 1.249.76 2.399 1.77 3.248 2.734 2.306 6.793 3 10.453 2.375 1.391-.237 2.748-.645 4.155-.787 1.44-.145 2.897 0 4.345-.062 2.942-.12 5.76-1.052 8.616-1.7A45.424 45.424 0 0 1 97 73.256a.32.32 0 0 1-.251-.007.273.273 0 0 1-.153-.182c-.336-.844-.353-1.762-.348-2.66A66.95 66.95 0 0 1 96.596 64Z" opacity=".1" />
              <path fill="#FF6363" fillRule="nonzero" d="M95.596 64a30.98 30.98 0 0 1-7.09.46c-.932-.045-1.868-.133-2.796-.054-1.12.097-2.197.435-3.288.68-5.655 1.268-11.564.03-17.337-.666-1.02-.123-2.061-.228-3.071-.048-.925.164-1.779.559-2.597.982a31.746 31.746 0 0 0-3.796 2.316c-1.259.896-2.529 2.038-2.616 3.489-.076 1.249.76 2.399 1.77 3.248 2.734 2.306 6.793 3 10.453 2.375 1.391-.237 2.748-.645 4.155-.787 1.44-.145 2.897 0 4.345-.062 2.942-.12 5.76-1.052 8.616-1.7A45.424 45.424 0 0 1 96 73.256a.32.32 0 0 1-.251-.007.273.273 0 0 1-.153-.182c-.336-.844-.353-1.762-.348-2.66A66.95 66.95 0 0 1 95.596 64Z" />
              <path fill="#000" fillRule="nonzero" d="M131 63.139c2.913.607 9.924.875 12.91.784 1.363-.039 2.722-.15 4.085-.147 2.336 0 4.668.35 6.998.208 3.01-.19 5.967-1.202 8.97-.941 1.476.13 2.896.56 4.303.99 1.407.43 2.94.917 3.93 1.963.917.97 1.252 2.274 1.556 3.527.17.7.34 1.423.19 2.124-.284 1.332-1.624 2.233-2.91 2.905-1.53.797-3.208 1.483-4.97 1.447-1.046-.021-2.063-.298-3.058-.585-2.854-.82-5.771-1.75-8.753-1.545-2.085.147-4.169.85-6.22.47-1.206-.223-2.294-.807-3.44-1.196-2.795-.936-9.889-.687-12.851-.428.172-1.524.15-3.06.323-4.586.134-1.167.262-2.376-.147-3.489-.257-.667-.343-1.029-.916-1.501Z" opacity=".1" />
              <path fill="#FF6363" fillRule="nonzero" d="M131 63.139c2.913.607 9.924.875 12.91.784 1.363-.039 2.722-.15 4.085-.147 2.336 0 4.668.35 6.998.208 3.01-.19 5.967-1.202 8.97-.941 1.476.13 2.896.56 4.303.99 1.407.43 2.94.917 3.93 1.963.917.97 1.252 2.274 1.556 3.527.17.7.34 1.423.19 2.124-.284 1.332-1.624 2.233-2.91 2.905-1.53.797-3.208 1.483-4.97 1.447-1.046-.021-2.063-.298-3.058-.585-2.854-.82-5.771-1.75-8.753-1.545-2.085.147-4.169.85-6.22.47-1.206-.223-2.294-.807-3.44-1.196-2.795-.936-9.889-.687-12.851-.428.172-1.524.15-3.06.323-4.586.134-1.167.262-2.376-.147-3.489-.257-.667-.343-1.029-.916-1.501Z" />
              <path fill="#FBBEBE" fillRule="nonzero" d="M100.689 32.984c-.456-.114-.63-.664-1.041-.885-.411-.221-.925-.047-1.22.282a1.954 1.954 0 0 0-.425 1.209c-.075 1.927 1.124 3.69 2.997 4.41a17.825 17.825 0 0 1-.467-5.165l.156.149Zm28.624 0c.456-.114.629-.664 1.04-.885.41-.221.924-.047 1.22.282.275.348.424.773.424 1.209.075 1.927-1.124 3.691-2.997 4.41.407-1.692.564-3.43.467-5.165l-.154.149Z" />
              <path fill="#000" fillRule="nonzero" d="M109.924 75.12a13.14 13.14 0 0 1 .55 3.922c0 2.305-.494 4.316-1.23 5.396-.062.513-.132 1.023-.2 1.523 1.038-.733 1.8-3.142 1.8-6.002-.008-1.954-.364-3.698-.92-4.84Zm5.034 0a13.136 13.136 0 0 0-.552 3.922c0 2.305.496 4.316 1.232 5.396.062.513.13 1.023.2 1.523-1.038-.733-1.8-3.142-1.8-6.002-.002-1.954.354-3.698.92-4.84Zm11.388-47.617c-.596.769-1.324 1.552-2.22 1.57-1.299.03-2.188-1.507-3.38-2.066-2.42-1.126-4.955 1.956-7.555 1.563-2.008-.304-3.579-2.653-5.602-2.44-1.446.151-2.503 1.573-3.394 2.884-.69-.949-1.378-1.9-2.066-2.851-.397-.556-.829-.954-1.021-1.365-.386.882.338 1.348 1.021 2.29.684.943 1.376 1.902 2.066 2.852.89-1.31 1.948-2.733 3.394-2.885 2.018-.213 3.594 2.137 5.602 2.44 2.584.393 5.125-2.688 7.555-1.562 1.192.556 2.083 2.096 3.38 2.067.896-.019 1.626-.802 2.22-1.57A28.973 28.973 0 0 0 129 24.332a7.806 7.806 0 0 0-.332-.333 29.296 29.296 0 0 1-2.322 3.503Z" opacity=".1" />
            </g>
          </g>
        </svg>
      </Section>

      <Section type="cardlist">
        <CardList posts={blogPosts} />
      </Section>

    </Layout>
  )
}

export default ThankYou

export const pageQuery = graphql`
  query WordpressPosts {
    allWpPost(limit: 3, sort: {fields: date, order: DESC}) {
      nodes {
          title
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          isSticky
          categories {
            nodes {
              slug
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 720
                    quality: 100
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP, AVIF]
                    breakpoints: [320, 720, 1024, 1600]
                  )
                }
              }
            }
          }
        }
      }
}
`